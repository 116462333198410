const strings = {
    SIGN_IN_TITLE: 'Sign in to Eventio.',
    SIGN_IN_MESSAGE: 'Enter your details below.',
    LOGIN_ERROR_MESSAGE:
        'Oops! That email and password combination is not valid.',
    SIGN_IN: 'Sign in',
    PROFILE: 'Profile',
    LOG_OUT: 'Log Out',
    SIGN_UP_QUESTION: "Don't have account?",
    SIGN_UP: 'Sign Up',
    EMAIL_LABEL: 'Email',
    PASSWORD_LABEL: 'Password',
    GENERAL_ERROR: 'Something is wrong',
    EDIT: 'Edit',
    LEAVE: 'Leave',
    JOIN: 'Join',
    ALL_EVENTS: 'All events',
    FUTURE_EVENTS: 'Future events',
    PAST_EVENTS: 'Past events',
    CREATE_NEW_EVENT_TITLE: 'Create new event',
    CREATE_NEW_EVENT_HINT: 'Enter details below.',
    CREATE_NEW_EVENT: 'Create new event',
    CREATE_NEW_EVENT_ERROR_MESSAGE:
        'Something went wrong, please try again in a minute.',
    NEW_EVENT_TITLE_LABEL: 'Title',
    NEW_EVENT_DESCRIPTION_LABEL: 'Description',
    NEW_EVENT_DATE_LABEL: 'Date',
    NEW_EVENT_TIME_LABEL: 'Time',
    NEW_EVENT_CAPACITY_LABEL: 'Capacity',
    INVALID_EMAIL: 'is invalid',
    CAN_NOT_BE_EMPTY: 'has to be filled up',
    NOT_A_NUMBER: 'is not a number',
    INVALID_DATE: 'has invalid date',
    INVALID_TIME: 'has invalid time',
    DATE_NOT_IN_FUTURE: 'Date has to be in the future',
    PAGE_404_TITLE: '404 Error - page not found',
    PAGE_404_DESCRIPTION:
        'Seems like Darth Vader just hits our website and drops it down.\nPlease press the refresh button and everything should be fine again.',
    REFRESH: 'Refresh',
    SIDE_COLUMN_TITLE: `"Great, kid. Don't get cocky."`,
    HAN_SOLO: 'Han Solo',
    SHOW: 'Show',
};

export default strings;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { LOCAL_STORAGE_REFRESH_ID } from '../constants';
import { IAuthUser, IDefaultProps } from '../types';
import Loading from '../uiComponents/Loading';
import { getToken } from '../utils';

export const withAuthentication = (
    WrappedComponent: React.ComponentType<IDefaultProps>
) => {
    return class WithAuthentication extends React.Component<
        IDefaultProps,
        IDefaultProps
    > {
        refreshIfNeeded = (authUser: IAuthUser) => {
            if (!authUser.data || (!authUser.data.token && !authUser.error)) {
                const cachedValue: string | null = getToken(
                    LOCAL_STORAGE_REFRESH_ID
                );
                if (!!cachedValue) {
                    this.props.signInRefreshToken({
                        refreshToken: cachedValue,
                    });
                }
            }
        };
        componentDidMount() {
            this.refreshIfNeeded(this.props.authUser);
        }

        render() {
            const { authUser } = this.props;
            if (authUser.loading) {
                return <Loading />;
            } else if (authUser.error) {
                return <Redirect to="/signIn" />;
            } else if (authUser.data && authUser.data.token) {
                return <WrappedComponent {...this.props} />;
            } else if (getToken(LOCAL_STORAGE_REFRESH_ID)) {
                return <Loading />;
            } else {
                return <Redirect to="/signIn" />;
            }
        }
    };
};

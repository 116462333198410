import React from 'react';

import strings from '../../../strings';
import AttendButton from './AttendButton';
import { IAttendButton } from '../../../uiComponents/types';

function JoinButton(param: IAttendButton) {
    const { id, text, disabled, onClick } = param;

    return (
        <AttendButton
            id={id}
            join={true}
            text={text || strings.JOIN}
            disabled={disabled}
            onClick={onClick}
        />
    );
}

export default JoinButton;

import React from 'react';

import { PATHS } from '../constants';
import strings from '../strings';
import Button from '../uiComponents/Button';

function SignUpLink() {
    return (
        <div className="sign-up-link">
            <span className="sign-up-question">{strings.SIGN_UP_QUESTION}</span>{' '}
            <Button
                text={strings.SIGN_UP}
                variant={'link'}
                linkTo={PATHS.signUp}
            />
        </div>
    );
}

export default SignUpLink;

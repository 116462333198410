import React from 'react';
import Logo from '../../Logo';
import Profile from '../../Profile';
import { connect } from 'react-redux';
import { IIsMobileStateProps } from '../../../types';

interface IBasicLayoutHeaderOwnProps {
    rightComponent?: JSX.Element;
}
interface IBasicLayoutHeaderProps
    extends IBasicLayoutHeaderOwnProps,
        IIsMobileStateProps {}
function BasicLayoutHeader(
    props: IBasicLayoutHeaderProps = {} as IBasicLayoutHeaderProps
) {
    const { rightComponent } = props;
    const { isMobile } = props.app;
    return (
        <div className="basic-layout-header">
            <div className="basic-layout-logo">
                <Logo mini={isMobile} />
            </div>
            <div className="basic-layout-right-component">
                {rightComponent || <Profile />}
            </div>
        </div>
    );
}

const mapStateToProps = (
    state: IIsMobileStateProps,
    ownProps: IBasicLayoutHeaderOwnProps
): IBasicLayoutHeaderProps => ({
    ...state,
    ...ownProps,
});

export default connect(mapStateToProps, null)(BasicLayoutHeader);

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../../actions';
import SignUpLink from '../../components/SignUpLink';
import strings from '../../strings';
import { IDefaultState, SignInData } from '../../types';
import Button from '../../uiComponents/Button';
import Input from '../../uiComponents/Input';

interface ISignInDispatchProps {
    signIn: (signInData: SignInData) => void;
}

interface ISignInForm extends ISignInDispatchProps, IDefaultState {}

function SignInForm(props: ISignInForm = {} as ISignInForm) {
    const { authUser, signIn } = props;
    const { isMobile } = props.app;

    const [email, setEmail] = useState('');
    // default true due to potentional automatic fill from the browser
    const [emailValid, setEmailValid] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);

    const signInOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        signIn({
            email,
            password,
        });
    };

    return (
        <form className="form" onSubmit={signInOnSubmit}>
            <Input
                label={strings.EMAIL_LABEL}
                type="text"
                name="email"
                value={email}
                validation={{ email: true, notEmpty: true }}
                disabled={authUser.loading}
                errorStyle={!!authUser.error}
                onChangeToState={setEmail}
                validHandlerToState={setEmailValid}
            />
            <Input
                label={strings.PASSWORD_LABEL}
                type={'password'}
                name="password"
                value={password}
                validation={{ notEmpty: true }}
                passwordEye={true}
                disabled={authUser.loading}
                errorStyle={!!authUser.error}
                onChangeToState={setPassword}
                validHandlerToState={setPasswordValid}
            />
            {isMobile && <SignUpLink />}
            <Button
                type="submit"
                disabled={!emailValid || !passwordValid || authUser.loading}
                loading={authUser.loading}
                text={strings.SIGN_IN}
            />
        </form>
    );
}

const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
});

const mapDispatchToProps = (dispatch: any): ISignInDispatchProps => ({
    signIn: (signInData: SignInData) =>
        dispatch(getActionFunction(actionTypes.authUser.loading, signInData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actionTypes } from '../actions';
import { LOCAL_STORAGE_REFRESH_ID, LOCAL_STORAGE_TOKEN_ID } from '../constants';
import { IAttendParams, IEvent, SignInData } from '../types';
import { BASE_URL } from './const';
import {
    fetchJSON,
    getDELETERequestInit,
    getGETRequestInit,
    getPOSTRequestInit,
    processFailedRequest,
} from './utils';

function* signIn(params: {
    data: SignInData | { refreshToken: string };
    type: string;
    repeat?: { data: any; type: string };
}) {
    const requestInit = getPOSTRequestInit({
        body: JSON.stringify({ ...params.data }),
    });

    try {
        const response = yield call(
            fetchJSON,
            `${BASE_URL}/auth/native`,
            requestInit
        );
        const token = response.headers.get('Authorization');
        const refreshToken = response.headers.get('Refresh-Token');

        let data;
        if (params.data.hasOwnProperty('refreshToken')) {
            localStorage.setItem(LOCAL_STORAGE_TOKEN_ID, token);
            data = { ...response.data, token };
        } else {
            localStorage.setItem(LOCAL_STORAGE_REFRESH_ID, refreshToken);
            localStorage.setItem(LOCAL_STORAGE_TOKEN_ID, token);
            data = { ...response.data, token, refreshToken };
        }
        yield put({
            type: actionTypes.authUser.success,
            data,
        });
        if (params.repeat) {
            yield put({
                ...params.repeat,
                repeated: true,
            });
        }
    } catch (error) {
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_ID);
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_ID);
        yield call(processFailedRequest, {
            error,
            errorType: actionTypes.authUser.error,
            data: null,
            repeated: true,
        });
    }
}

function* getAllEvents(params: { type: string; repeated?: boolean }) {
    const requestInit = getGETRequestInit();

    try {
        const { data } = yield call(
            fetchJSON,
            `${BASE_URL}/events`,
            requestInit
        );

        yield put({
            type: actionTypes.events.getAll.success,
            data,
        });
    } catch (error) {
        yield call(processFailedRequest, {
            error,
            errorType: actionTypes.events.getAll.error,
            data: null,
            repeated: params.repeated || false,
        });
    }
}

function* attend(params: {
    data: IAttendParams;
    type: string;
    repeated?: boolean;
}) {
    const { join, id } = params.data;
    const requestInit = join
        ? getPOSTRequestInit({
              body: '',
              token: true,
          })
        : getDELETERequestInit(true);

    try {
        const response = yield call(
            fetchJSON,
            `${BASE_URL}/events/${id}/attendees/me`,
            requestInit
        );

        yield put({
            type: actionTypes.events.attend.success,
            data: response.data,
        });
        yield put({ type: actionTypes.events.getAll.noLoading });
    } catch (error) {
        yield call(processFailedRequest, {
            error,
            errorType: actionTypes.events.attend.error,
            data: params.data,
            repeated: params.repeated || false,
        });
    }
}

function* createEvent(params: {
    data: IEvent;
    type: string;
    repeated?: boolean;
}) {
    const requestInit = getPOSTRequestInit({
        body: JSON.stringify({ ...params.data }),
        token: true,
    });

    try {
        const response = yield call(
            fetchJSON,
            `${BASE_URL}/events`,
            requestInit
        );

        yield put({
            type: actionTypes.events.create.success,
            data: response.data,
        });
        yield put({ type: actionTypes.events.getAll.loading });
    } catch (error) {
        processFailedRequest({
            error,
            errorType: actionTypes.events.create.error,
            data: params.data,
            repeated: params.repeated || false,
        });
    }
}

function* actionWatcher() {
    yield takeLatest(actionTypes.authUser.loading, signIn);
    yield takeLatest(actionTypes.events.getAll.noLoading, getAllEvents);
    yield takeLatest(actionTypes.events.getAll.loading, getAllEvents);
    yield takeLatest(actionTypes.events.attend.loading, attend);
    yield takeLatest(actionTypes.events.create.loading, createEvent);
}

export default function* rootSaga() {
    yield all([actionWatcher()]);
}

import React from 'react';

import logoPath from '../assets/images/logo.svg';
import logoPathMini from '../assets/images/logoMini.svg';
import { Link } from 'react-router-dom';
import { PathNameType } from '../types';

interface ILogo {
    mini?: boolean;
    linkTo?: PathNameType;
}
function Logo(params: ILogo = {} as ILogo) {
    const { linkTo, mini } = params;
    return (
        <Link to={linkTo || '/'}>
            <img src={mini ? logoPathMini : logoPath} alt=".E" />
        </Link>
    );
}

export default Logo;

import React, { useEffect, useState } from 'react';

import BasicLayout from '../../components/BasicLayout';
import { PATHS } from '../../constants';
import { withState } from '../../HOC/withState';
import {
    EEventSwitcher,
    EventSwitcher,
    IDefaultProps,
    IEventData,
} from '../../types';
import PlusButton from '../../uiComponents/Button/PlusButton';
import Error from '../../uiComponents/Error';
import Loading from '../../uiComponents/Loading';
import DashboardPanel from './DashboardPanel';
import SingleEvent from './SingleEvent/index';
import { getFilteredEvents } from './utils';

// data fetched right after login
function EventList(props: IDefaultProps = {} as IDefaultProps) {
    const { events } = props;
    const { eventSwitcher } = props.app;
    const [filteredData, setFilteredData] = useState(events.data || []);
    const [eventSwitcherString, setEventSwitcherString] = useState(
        'grid' as EventSwitcher
    );

    useEffect(() => {
        if (!props.events.loading) {
            props.getEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setEventSwitcherString(
            eventSwitcher === EEventSwitcher.grid
                ? ('grid' as EventSwitcher)
                : ('list' as EventSwitcher)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSwitcher]);

    useEffect(() => {
        setFilteredData(
            getFilteredEvents(props.events.data, props.app.eventFilter)
        );
    }, [props.app.eventFilter, props.events.data]);

    return (
        <React.Fragment>
            <BasicLayout
                content={
                    <div className={`event-list-${eventSwitcherString}`}>
                        <DashboardPanel />
                        {events.loading &&
                        events.data &&
                        events.data.length < 2 ? (
                            <Loading />
                        ) : events.error ? (
                            <Error />
                        ) : (
                            filteredData && [
                                ...filteredData.map((event: IEventData) => (
                                    <SingleEvent
                                        key={event._id}
                                        event={event}
                                        eventSwitcherString={
                                            eventSwitcherString
                                        }
                                    />
                                )),
                                Array.from(
                                    Array(
                                        (3 - (filteredData.length % 3)) % 3
                                    ).keys()
                                ).map((key: number) => (
                                    <div
                                        className="invisible-event-card"
                                        key={Math.random() % 10}
                                    ></div>
                                )),
                            ]
                        )}
                    </div>
                }
            />
            <PlusButton linkTo={PATHS.createEvent} />
        </React.Fragment>
    );
}

export default withState(EventList);

import React from 'react';

import { withState } from '../../../HOC/withState';
import strings from '../../../strings';
import { IDefaultProps } from '../../../types';
import { ButtonVariant, DropDownVariant } from '../../../uiComponents/types';
import DropDown from '../../../uiComponents/DropDown';
import TabPanel from '../../../uiComponents/TabPanel';

const tabs = [strings.ALL_EVENTS, strings.FUTURE_EVENTS, strings.PAST_EVENTS];

function Filter(props: IDefaultProps = {} as IDefaultProps) {
    const { eventFilterUpdate } = props;
    const { isMobile } = props.app;

    const onSelectedHandler = (key: number) => {
        eventFilterUpdate(key);
    };

    return (
        <div className="filter">
            {isMobile ? (
                <DropDown
                    label={<React.Fragment>{strings.SHOW}:</React.Fragment>}
                    items={tabs}
                    variant={'filter' as DropDownVariant}
                    onSelected={onSelectedHandler}
                />
            ) : (
                <TabPanel
                    tabs={tabs}
                    variant={'tab' as ButtonVariant}
                    onSelected={onSelectedHandler}
                />
            )}
        </div>
    );
}

export default withState(Filter);

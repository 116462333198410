import React from 'react';
import SignUpLink from '../../SignUpLink';

function LeftPanelLayoutHeader() {
    return (
        <div className="header">
            <SignUpLink />
        </div>
    );
}

export default LeftPanelLayoutHeader;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { actionTypes, getActionFunction } from '../../actions';
import strings from '../../strings';
import { IEvent, IInStore, PathNameType } from '../../types';
import Button from '../../uiComponents/Button';
import Input from '../../uiComponents/Input';
import { getDateTimeFromStrings } from '../../utils';

interface ICreateEventDispatchProps {
    createNewEvent: (event: IEvent) => void;
}
interface ICreateEventFromStateProps {
    createEvent: IInStore;
}
interface ICreateEventOwnProps {
    linkTo?: PathNameType;
}
interface ICreateEventStateProps
    extends ICreateEventFromStateProps,
        ICreateEventOwnProps {}

interface ICreateEventProps
    extends ICreateEventDispatchProps,
        ICreateEventStateProps {}

function CreateEventForm(props: ICreateEventProps = {} as ICreateEventProps) {
    const { createEvent, linkTo, createNewEvent } = props;

    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionValid, setDescriptionValid] = useState(false);
    const [date, setDate] = useState('');
    const [dateValid, setDateValid] = useState(false);
    const [time, setTime] = useState('');
    const [timeValid, setTimeValid] = useState(false);
    const [capacity, setCapacity] = useState('');
    const [capacityValid, setCapacityValid] = useState(false);
    const [futureDateTimeError, setFutureDateTimeError] = useState('');
    const [createInProgress, setCreateInProgress] = useState(false);

    const createEventOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const startsAt = getDateTimeFromStrings({ date, time }).toISOString();
        createNewEvent({
            title,
            description,
            startsAt,
            capacity: +capacity,
        });
        setCreateInProgress(true);
    };

    const futureDateValidationHandler = (
        valid: boolean,
        stateUpdateFunction: (newValue: boolean) => void
    ) => {
        if (valid) {
            let eventDate = getDateTimeFromStrings({ date, time });
            valid = +eventDate.getTime() > +Date.now();
            if (!valid) {
                setFutureDateTimeError(strings.DATE_NOT_IN_FUTURE);
            } else {
                setFutureDateTimeError('');
                setTimeValid(true);
                setDateValid(true);
            }
        }
        stateUpdateFunction(valid);
    };

    if (createInProgress && !createEvent.loading && !createEvent.error) {
        return <Redirect to={linkTo || '/'} />;
    }

    return (
        <form className="form" onSubmit={createEventOnSubmit}>
            <Input
                label={strings.NEW_EVENT_TITLE_LABEL}
                type="text"
                name="title"
                value={title}
                validation={{ notEmpty: true }}
                disabled={createEvent.loading}
                errorStyle={!!createEvent.error}
                onChangeToState={setTitle}
                validHandlerToState={setTitleValid}
            />
            <Input
                label={strings.NEW_EVENT_DESCRIPTION_LABEL}
                type={'text'}
                name="description"
                value={description}
                validation={{ notEmpty: true }}
                disabled={createEvent.loading}
                errorStyle={!!createEvent.error}
                onChangeToState={setDescription}
                validHandlerToState={setDescriptionValid}
            />
            <Input
                label={strings.NEW_EVENT_DATE_LABEL}
                type={'text'}
                name="date"
                value={date}
                validation={{ notEmpty: true, date: true }}
                disabled={createEvent.loading}
                errorStyle={!!createEvent.error}
                customErrorMessage={futureDateTimeError}
                onChangeToState={setDate}
                validHandler={(valid: boolean) =>
                    futureDateValidationHandler(valid, setDateValid)
                }
            />
            <Input
                label={strings.NEW_EVENT_TIME_LABEL}
                type={'text'}
                name="time"
                value={time}
                validation={{ notEmpty: true, time: true }}
                disabled={createEvent.loading}
                errorStyle={!!createEvent.error}
                customErrorMessage={futureDateTimeError}
                onChangeToState={setTime}
                validHandler={(valid: boolean) =>
                    futureDateValidationHandler(valid, setTimeValid)
                }
            />
            <Input
                label={strings.NEW_EVENT_CAPACITY_LABEL}
                type={'text'}
                name="capacity"
                value={capacity}
                validation={{ notEmpty: true, number: true }}
                disabled={createEvent.loading}
                errorStyle={!!createEvent.error}
                onChangeToState={setCapacity}
                validHandlerToState={setCapacityValid}
            />
            <Button
                type="submit"
                disabled={
                    createEvent.loading ||
                    !titleValid ||
                    !descriptionValid ||
                    !dateValid ||
                    !timeValid ||
                    !capacityValid
                }
                text={strings.CREATE_NEW_EVENT}
            />
        </form>
    );
}

const mapStateToProps = (
    state: ICreateEventFromStateProps,
    ownProps: ICreateEventOwnProps
): ICreateEventStateProps => ({
    ...state,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: any): ICreateEventDispatchProps => ({
    createNewEvent: (event: IEvent) =>
        dispatch(getActionFunction(actionTypes.events.create.loading, event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventForm);

import React from 'react';
import Button from '.';
import { ITab } from '../types';

function Tab(param: ITab) {
    const { id, text, selected, variant, onClick } = param;

    return (
        <Button
            key={id}
            variant={variant || 'tab'}
            text={text}
            selected={selected}
            onClick={() => onClick && onClick(id)}
            disabled={selected}
        />
    );
}

export default Tab;

import React from 'react';
import fullScreenSpinner from '../assets/images/fullScreenSpinner.svg';

function Loading() {
    return (
        <div className="loading-container">
            <div
                className="loading"
                style={{
                    maskImage: `url(${fullScreenSpinner})`,
                    WebkitMaskImage: `url(${fullScreenSpinner})`,
                }}
            >
                <div className="loading-mask"></div>
            </div>
        </div>
    );
}

export default Loading;

import React from 'react';

import LeftPanelLayoutHeader from './LeftPanelLayoutHeader/index';
import LeftPanel from '../LeftPanel';
import Logo from '../Logo';
import { IIsMobileStateProps } from '../../types';
import { connect } from 'react-redux';

interface ILeftPanelOwnProps {
    content: JSX.Element;
}
interface ILeftPanelProps extends ILeftPanelOwnProps, IIsMobileStateProps {
    content: JSX.Element;
}
function LeftPanelLayout(params: ILeftPanelProps = {} as ILeftPanelProps) {
    const { content } = params;
    const { isMobile } = params.app;

    return (
        <div className="left-panel-layout-body">
            {!isMobile && <LeftPanel />}
            <div className="right-panel-body">
                {!isMobile ? (
                    <LeftPanelLayoutHeader />
                ) : (
                    <div className="logo">
                        <Logo mini={isMobile} />
                    </div>
                )}
                <div className="content-container">{content}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (
    state: IIsMobileStateProps,
    ownProps: ILeftPanelOwnProps
): IIsMobileStateProps => ({
    ...state,
    ...ownProps,
});

export default connect(mapStateToProps, null)(LeftPanelLayout);

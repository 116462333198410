import React from 'react';

import plusIcon from '../../assets/images/plus.svg';
import Button from './';
import { IPlusButton } from '../types';

function PlusButton(param: IPlusButton) {
    const { linkTo, onClick } = param;

    return (
        <Button
            variant={'plus'}
            text={<img src={plusIcon} alt={'+'} />}
            onClick={onClick}
            linkTo={linkTo}
        />
    );
}

export default PlusButton;

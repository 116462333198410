import { EEventFilter, IEventData } from '../../types';

export const isEventInTheFuture = (eventsDateString: string): boolean =>
    +Date.now().toString() <= new Date(eventsDateString).getTime();

export const getFilteredEvents = (
    events: IEventData[],
    eventFilter: EEventFilter
) => {
    if (eventFilter === EEventFilter.future) {
        return events.filter((event: IEventData) => {
            return isEventInTheFuture(event.startsAt);
        });
    } else if (eventFilter === EEventFilter.past) {
        return events.filter((event: IEventData) => {
            return !isEventInTheFuture(event.startsAt);
        });
    }
    // EEventFilter.all
    return events;
};

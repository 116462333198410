import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/spinner.svg';
import { IButton } from '../types';

function Button(param: IButton) {
    const { text, disabled, selected, loading, linkTo, onClick } = param;
    let { type = 'button', variant } = param;

    let buttonWithoutLink;

    if (
        variant === 'switch' ||
        variant === 'link' ||
        variant === 'tab' ||
        variant === 'close'
    ) {
        const className = `link-button${
            variant ? ' link-button-' + variant : ''
        }${selected ? ` link-button-${variant}-selected` : ''}`;
        buttonWithoutLink = (
            <span className={className} onClick={onClick}>
                {text}
            </span>
        );
    } else {
        const className = `button button-${type}${
            variant ? ' button-' + variant : ''
        }${disabled && !loading ? ' button-disabled' : ''}`;
        buttonWithoutLink = (
            <button
                className={className}
                type={type}
                disabled={disabled || selected}
                onClick={onClick}
            >
                {loading ? (
                    <div
                        className="button-loading"
                        style={{
                            maskImage: `url(${backgroundImage})`,
                            WebkitMaskImage: `url(${backgroundImage})`,
                        }}
                    >
                        <div className="button-loading-mask"></div>
                    </div>
                ) : (
                    text
                )}
            </button>
        );
    }
    if (linkTo && !disabled) {
        return <Link to={linkTo}>{buttonWithoutLink}</Link>;
    }
    return buttonWithoutLink;
}

export default Button;

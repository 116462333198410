import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../../../actions';
import strings from '../../../strings';
import { IAttendParams, IInStore } from '../../../types';
import Error from '../../../uiComponents/Error';
import { IAttendButton } from '../../../uiComponents/types';
import Button from '../../../uiComponents/Button/index';

interface IDispatchProps {
    attendDispatch: (params: IAttendParams) => void;
}

interface IOwnProps extends IAttendButton {
    join: boolean;
}
interface IAttendProps {
    attend: IInStore;
}
interface IStateProps extends IOwnProps, IAttendProps {}

interface IProps extends IStateProps, IDispatchProps {}

function AttendButton(props: IProps) {
    const { id, text, disabled, join, onClick, attendDispatch } = props;
    const { loading, error } = props.attend;

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (!loading && processing) {
            setProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const onAttendClick = () => {
        attendDispatch({ id, join });
        onClick && onClick();
        setProcessing(true);
    };

    if (error) {
        // TODO move error handle to somewhere else
        return <Error />;
    }

    return (
        <Button
            text={text || strings.LEAVE}
            type={'button'}
            loading={loading && processing}
            variant={join ? 'join' : 'leave'}
            disabled={disabled}
            onClick={onAttendClick}
        />
    );
}

const mapStateToProps = (
    state: IAttendProps,
    ownProps: IOwnProps
): IStateProps => ({
    attend: state.attend,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps => ({
    attendDispatch: (params: IAttendParams) =>
        dispatch(getActionFunction(actionTypes.events.attend.loading, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendButton);

import { MOBILE_SCREEN_WIDTH } from './constants';
import { TokenIdType } from './types';

export const isThisScreenSizeMobileSize = (): boolean =>
    window.innerWidth <= MOBILE_SCREEN_WIDTH;

export const getToken = (tokenId: TokenIdType) => {
    const value: string | null = localStorage.getItem(tokenId);
    if (value && value.toLowerCase() !== 'null') {
        return value;
    } else {
        return null;
    }
};

export const getDateTimeFromStrings = (params: {
    date: string;
    time: string;
}): Date => {
    const { date, time } = params;
    let dateTime = new Date(date);
    let separatedTime = time.split(':');
    dateTime.setHours(+separatedTime[0], +separatedTime[1], 0, 0);
    return dateTime;
};

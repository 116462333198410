import { put, call } from 'redux-saga/effects';
import { actionTypes } from '../actions';

import { LOCAL_STORAGE_REFRESH_ID, LOCAL_STORAGE_TOKEN_ID } from '../constants';
import { getToken } from '../utils';
import { API_KEY } from './const';
import { IHeader } from './types';

export const getHeaders = (token?: boolean) => {
    const headers: IHeader = {
        'Content-Type': 'application/json',
        APIKey: API_KEY,
    };
    if (token) {
        headers.Authorization = getToken(LOCAL_STORAGE_TOKEN_ID) as string;
    }
    return headers;
};

export const getPOSTRequestInit = (params: {
    body: string;
    token?: boolean;
}) => {
    const { body, token } = params;
    return {
        body,
        method: 'POST',
        headers: getHeaders(token),
    };
};

export const getGETRequestInit = () => {
    return {
        method: 'GET',
        headers: getHeaders(),
    };
};

export const getDELETERequestInit = (token: boolean) => {
    return {
        method: 'DELETE',
        headers: getHeaders(token),
    };
};

export const fetchJSON = (url: string, requestInit = {}): Promise<Response> =>
    new Promise<Response>(async (resolve, reject) => {
        try {
            const response: Response = await fetch(url, requestInit);
            let response_1 =
                response.status >= 200 && response.status <= 207
                    ? response
                    : reject(response);
            let response_2 =
                (((response_1 &&
                    response_1.json().then((json) => ({
                        headers: response_1 && response_1.headers,
                        data: json,
                    }))) as unknown) as Response) || reject(response);
            return resolve(response_2);
        } catch (error) {
            return reject(error);
        }
    });

export function* repeatWithNewToken(params: any) {
    yield put({
        type: actionTypes.authUser.loading,
        data: {
            refreshToken: getToken(LOCAL_STORAGE_REFRESH_ID) as string,
        },
        repeat: params,
    });
}

// TODO
export const getMessageFromErrorStatus = (error: { status: number }) => {
    let message;
    switch (error.status) {
        // case 500:
        //     message = 'Internal Server Error';
        //     break;
        // case 401:
        //     message = 'Invalid credentials';
        //     break;
        default:
            message = error.status;
    }
    return message;
};

export function* processFailedRequest(
    // TODO errorType should be action type
    // TODO data should be type of any of IParam of sagas functions
    params: { error: Response; errorType: any; data: any; repeated: boolean }
) {
    const { error, errorType, data, repeated } = params;
    if (!repeated && (error.status === 401 || error.status === 403)) {
        yield call(repeatWithNewToken, data);
    } else {
        yield put({
            type: errorType,
            error: getMessageFromErrorStatus(error),
        });
    }
}

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LeftPanelLayout from '../../components/LeftPanelLayout/index';
import strings from '../../strings';
import { IAuthUser } from '../../types';
import SignInForm from './SignInForm';

export interface ISignInProps {
    authUser: IAuthUser;
}

function SignIn(props: ISignInProps = {} as ISignInProps) {
    const { authUser } = props;
    if (authUser.data && authUser.data.token) {
        return <Redirect to="/" />;
    }

    return (
        <LeftPanelLayout
            content={
                <div className="content">
                    <h1>{strings.SIGN_IN_TITLE}</h1>
                    {authUser.error ? (
                        <div className="message error-color">
                            {strings.LOGIN_ERROR_MESSAGE}
                        </div>
                    ) : (
                        <div className="message info-color">
                            {strings.SIGN_IN_MESSAGE}
                        </div>
                    )}
                    <SignInForm />
                </div>
            }
        />
    );
}

const mapStateToProps = (state: ISignInProps): ISignInProps => ({ ...state });

export default connect(mapStateToProps, null)(SignIn);

import React, { useState } from 'react';
import eye from '../assets/images/eye.svg';
import strings from '../strings';
import { EMAIL_PATTERN, TIME_PATTERN } from './constants';
import { IInput, IValidation } from './types';

function Input(param: IInput) {
    const {
        label,
        type,
        name,
        value,
        validation,
        passwordEye,
        disabled,
        errorStyle,
        customErrorMessage,
        onChange,
        onChangeToState,
        onBlur,
        onBlurToState,
        validHandler,
        validHandlerToState,
    } = param;
    const [emailError, setEmailError] = useState('');
    const [notEmptyError, setNotEmptyError] = useState('');
    const [naNError, setNaNError] = useState('');
    const [invalidDate, setInvalidDate] = useState('');
    const [invalidFutureDate, setInvalidFutureDate] = useState('');
    const [invalidTime, setInvalidTime] = useState('');
    const [passwordType, setPasswordType] = useState(true);

    const isValid = (param: IValidation) => {
        const { setErrors, value } = param;
        let valid = true;

        if (validation) {
            if (validation.notEmpty && !value) {
                setErrors && setNotEmptyError(strings.CAN_NOT_BE_EMPTY);
                valid = false;
            } else {
                setNotEmptyError('');
            }
            if (validation.email && !EMAIL_PATTERN.test(value)) {
                setErrors && setEmailError(strings.INVALID_EMAIL);
                valid = false;
            } else {
                setEmailError('');
            }
            if (validation.number && !Number.isInteger(+value)) {
                setErrors && setNaNError(strings.NOT_A_NUMBER);
                valid = false;
            } else {
                setNaNError('');
            }
            if (validation.date && isNaN(new Date(value).getTime())) {
                setErrors && setInvalidDate(strings.INVALID_DATE);
                valid = false;
            } else {
                setInvalidDate('');
            }
            // if date is today, time is not tested in here,
            // has to be handled outside Input component because of the unknown time
            if (
                validation.futureDate &&
                new Date(value).getTime() <
                    +new Date().setHours(0, 0, 0, 0).toString()
            ) {
                setErrors && setInvalidFutureDate(strings.DATE_NOT_IN_FUTURE);
                valid = false;
            } else {
                setInvalidFutureDate('');
            }
            if (validation.time && !TIME_PATTERN.test(value)) {
                setErrors && setInvalidTime(strings.INVALID_TIME);
                valid = false;
            } else {
                setInvalidTime('');
            }
        }
        return valid;
    };

    const processValidation = (param: IValidation) => {
        const valid = isValid(param);
        validHandler && validHandler(valid);
        validHandlerToState && saveValidToState(valid, validHandlerToState);
    };

    const saveChangeToState = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.FocusEvent<HTMLInputElement>,
        stateUpdateFunction: (newValue: string) => void
    ) => {
        stateUpdateFunction(event.target.value);
    };

    const saveValidToState = (
        valid: boolean,
        stateUpdateFunction: (newValue: boolean) => void
    ) => {
        stateUpdateFunction(valid);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        processValidation({ value: event.target.value, setErrors: false });
        onChange && onChange(event);
        onChangeToState && saveChangeToState(event, onChangeToState);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        processValidation({
            value: event.target.value,
            setErrors: true,
        });
        onBlur && onBlur(event);
        onBlurToState && saveChangeToState(event, onBlurToState);
    };

    const eyeChange = () => {
        setPasswordType(!passwordType);
    };

    const errorMessage = customErrorMessage
        ? `${label || name} ${customErrorMessage}`
        : [
              emailError,
              notEmptyError,
              naNError,
              invalidDate,
              invalidFutureDate,
              invalidTime,
          ]
              .filter((error: string | undefined) => error && error.length > 0)
              .map((error: string | undefined) => `${label || name} ${error}`)
              .join(', ');

    return (
        <React.Fragment>
            <div className="form-field">
                <input
                    className={`input${
                        errorMessage || errorStyle ? ' input-error-color' : ''
                    }`}
                    id={name}
                    type={
                        passwordEye && type === 'password'
                            ? passwordType
                                ? 'password'
                                : 'text'
                            : type
                    }
                    name={name}
                    value={value}
                    placeholder={' '}
                    disabled={disabled}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                />
                <label className="label" htmlFor={name}>
                    {label}
                </label>
                {passwordEye && (
                    <span className="password-eye" onClick={eyeChange}>
                        <img src={eye} alt={passwordType ? 'eye' : 'noEye'} />
                    </span>
                )}
            </div>

            {errorMessage && <div className="input-error">{errorMessage}</div>}
        </React.Fragment>
    );
}

export default Input;

export const actionTypes = {
    app: {
        isMobileUpdate: 'IS_MOBILE_UPDATE',
        eventFilterUpdate: 'EVENT_FILTER_UPDATE',
        eventSwitcherUpdate: 'EVENT_SWITCHER_UPDATE',
    },
    authUser: {
        loading: 'AUTH_USER_LOADING',
        error: 'AUTH_USER_ERROR',
        success: 'AUTH_USER_SUCCESS',
    },
    events: {
        getAll: {
            noLoading: 'EVENTS_GET_ALL_NO_LOADING',
            loading: 'EVENTS_GET_ALL_LOADING',
            error: 'EVENTS_GET_ALL_ERROR',
            success: 'EVENTS_GET_ALL_SUCCESS',
        },
        attend: {
            loading: 'EVENTS_ATTEND_LOADING',
            error: 'EVENTS_ATTEND_ERROR',
            success: 'EVENTS_ATTEND_SUCCESS',
        },
        create: {
            loading: 'EVENTS_CREATE_LOADING',
            error: 'EVENTS_CREATE_ERROR',
            success: 'EVENTS_CREATE_SUCCESS',
        },
    },
};

export const getActionFunction = (action: string, data?: any) => {
    return { type: action, data };
};

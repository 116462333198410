import React, { useState } from 'react';
import { ITabPanel } from './types';

import Tab from './Button/Tab';

function TabPanel(param: ITabPanel) {
    const { tabs, variant, onSelected } = param;

    const [selected, setSelected] = useState(0);

    const onClickHandle = (id: number) => {
        setSelected(id);
        onSelected && onSelected(id);
    };

    return (
        <React.Fragment>
            {(tabs as any[]).map((tabText: any, index: number) => (
                <Tab
                    key={index.toString()}
                    id={index}
                    text={tabText}
                    variant={variant}
                    onClick={onClickHandle}
                    selected={index === selected}
                />
            ))}
        </React.Fragment>
    );
}

export default TabPanel;

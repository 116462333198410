import React from 'react';
import Filter from './Filter';
import Switcher from './Switcher';

function DashboardPanel() {
    return (
        <div className="dashboard-panel">
            <Filter />
            <Switcher />
        </div>
    );
}

export default DashboardPanel;

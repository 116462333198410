import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actionTypes, getActionFunction } from './actions';

import { PATHS } from './constants';
import CreateEvent from './pages/CreateEvent';
import EventList from './pages/EventList/index';
import Page404 from './pages/Page404';
import SignIn from './pages/SignIn/index';
import { IIsMobileDispatchProps, IIsMobileProps, PathNameType } from './types';
import { isThisScreenSizeMobileSize } from './utils';

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

function MainRouter(props: IIsMobileProps = {} as IIsMobileProps) {
    const [path, setPath] = useState(PATHS.eventList);
    const { updateIsMobile } = props;
    const { isMobile } = props.app;

    const location = useLocation();
    const pathName: PathNameType =
        location && (location.pathname as PathNameType);
    useEffect(() => {
        const handleResize = () => {
            let newIsMobile = isThisScreenSizeMobileSize();
            if (newIsMobile !== isMobile) {
                updateIsMobile(newIsMobile);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, updateIsMobile]);

    useEffect(() => {
        if (!firebase.apps.length) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
        }
        updateIsMobile(isThisScreenSizeMobileSize());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPath(pathName);
    }, [pathName]);

    switch (path) {
        case PATHS.eventList:
            return <EventList />;
        case PATHS.signIn:
            return <SignIn />;
        case PATHS.createEvent:
            return <CreateEvent />;
        default:
            return <Page404 />;
    }
}

const mapStateToProps = (state: IIsMobileProps): IIsMobileProps => ({
    ...state,
});

const mapDispatchToProps = (dispatch: any): IIsMobileDispatchProps => ({
    updateIsMobile: (isMobile: boolean) =>
        dispatch(getActionFunction(actionTypes.app.isMobileUpdate, isMobile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);

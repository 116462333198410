import React from 'react';
import { connect } from 'react-redux';
import backgroundImage from '../assets/images/hanSolo.png';
import strings from '../strings';
import { IIsMobileStateProps } from '../types';
import Logo from './Logo';

function LeftPanel(props: IIsMobileStateProps = {} as IIsMobileStateProps) {
    const { isMobile } = props.app;
    return (
        <div className="left-panel-body">
            <div className="logo">
                <Logo mini={isMobile} />
            </div>
            <div className="left-panel-mask">
                <div
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                    className="left-panel-background-image"
                />
            </div>
            <div className="left-panel-quote">
                <div className="left-panel-title">
                    {strings.SIDE_COLUMN_TITLE}
                </div>
                <div className="left-panel-rectangle" />
                <div className="left-panel-han-solo">{strings.HAN_SOLO}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: IIsMobileStateProps): IIsMobileStateProps => ({
    ...state,
});

export default connect(mapStateToProps, null)(LeftPanel);

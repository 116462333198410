import React from 'react';

import { withState } from '../../../HOC/withState';
import { IDefaultProps } from '../../../types';
import { ButtonVariant } from '../../../uiComponents/types';
import TabPanel from '../../../uiComponents/TabPanel';
import gridView from '../../../assets/images/gridView.svg';
import listView from '../../../assets/images/listView.svg';

const tabs = [gridView, listView].map((svgPath: string) => (
    <img src={svgPath} alt={svgPath} />
));

function Switcher(props: IDefaultProps = {} as IDefaultProps) {
    const { eventSwitcherUpdate } = props;

    const onSelectedHandler = (key: number) => {
        eventSwitcherUpdate(key);
    };

    return (
        <div className="switcher">
            <TabPanel
                tabs={tabs}
                variant={'switch' as ButtonVariant}
                onSelected={onSelectedHandler}
            />
        </div>
    );
}

export default withState(Switcher);

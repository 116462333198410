import { PathNameType, TokenIdType } from './types';

export const LOCAL_STORAGE_REFRESH_ID = 'eventioR' as TokenIdType;
export const LOCAL_STORAGE_TOKEN_ID = 'eventioT' as TokenIdType;
export const PATHS = {
    eventList: '/' as PathNameType,
    signIn: '/signIn' as PathNameType,
    createEvent: '/createEvent' as PathNameType,
    signUp: '/signUp' as PathNameType,
    profile: '/profile' as PathNameType,
};

export const MOBILE_SCREEN_WIDTH = 600;
export const ERROR_MESSAGE_COLOR = '#FC226E';
export const INFO_MESSAGE_COLOR = '#949EA8';

import React from 'react';
import { useHistory } from 'react-router-dom';

import {
    LOCAL_STORAGE_REFRESH_ID,
    LOCAL_STORAGE_TOKEN_ID,
    PATHS,
} from '../constants';
import { withState } from '../HOC/withState';
import strings from '../strings';
import { IDefaultProps } from '../types';
import DropDown from '../uiComponents/DropDown';
import { DropDownVariant } from '../uiComponents/types';

enum EProfileItems {
    profile,
    logOut,
}
const profileItems = [strings.PROFILE, strings.LOG_OUT];

function Profile(props: IDefaultProps = {} as IDefaultProps) {
    const { authUser, logOut } = props;
    const { isMobile } = props.app;
    const history = useHistory();

    const onSelectedHandler = (key: number) => {
        if (key === EProfileItems.logOut) {
            logOut();
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_ID);
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_ID);
        } else {
            history.push(PATHS.profile);
        }
    };

    return (
        <React.Fragment>
            <DropDown
                label={
                    <div className="profile-icon">
                        {`${authUser.data.firstName[0]} ${authUser.data.lastName[0]}`}
                    </div>
                }
                title={
                    !isMobile
                        ? `${authUser.data.firstName} ${authUser.data.lastName}`
                        : ' '
                }
                items={profileItems}
                variant={'profile' as DropDownVariant}
                onSelected={onSelectedHandler}
            />
        </React.Fragment>
    );
}

export default withState(Profile);

export interface IIsMobileStateProps {
    app: IApp;
}

export interface IIsMobileDispatchProps {
    updateIsMobile: (isMobile: boolean) => void;
}

export interface IIsMobileProps
    extends IIsMobileStateProps,
        IIsMobileDispatchProps {}

export enum EEventFilter {
    all,
    future,
    past,
}

export declare type EventSwitcher = 'grid' | 'list';
export enum EEventSwitcher {
    grid,
    list,
}

export interface IUser {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}

export interface IAuthUserData extends IUser {
    token: string;
    refreshToken: string;
}

export interface IEvent {
    title: string;
    description: string;
    startsAt: string;
    capacity: number;
}

export interface IEventData extends IEvent {
    _id: string;
    updatedAt: string;
    createdAt: string;
    owner: IUser;
    attendees: IUser[];
}

export declare type SignInData = {
    email: string;
    password: string;
};

export declare type RefreshTokenType = { refreshToken: string };

export declare type PathNameType =
    | '/'
    | '/signIn'
    | '/signUp'
    | '/createEvent'
    | '/profile';

export declare type NotificationType = 'success' | 'info' | 'error' | 'warning';

export declare type TokenIdType = 'eventioR' | 'eventioT';

export interface IInStore {
    loading: boolean;
    error: string;
}
export interface IAuthUser extends IInStore {
    data: IAuthUserData;
}
export interface IEvents extends IInStore {
    data: IEventData[];
}
export interface IApp {
    isMobile: boolean;
    eventFilter: EEventFilter;
    eventSwitcher: EEventSwitcher;
}

export interface IDefaultState {
    app: IApp;
    authUser: IAuthUser;
    events: IEvents;
}

export interface IDefaultDispatchProps {
    signIn: (signInData: SignInData) => void;
    logOut: () => void;
    signInRefreshToken: (refreshToken: RefreshTokenType) => void;
    getEvents: () => void;
    eventFilterUpdate: (eventFilter: EEventFilter) => void;
    eventSwitcherUpdate: (eventSwitcher: EEventSwitcher) => void;
}

export interface IDefaultProps extends IDefaultState, IDefaultDispatchProps {}

export interface IAttendParams {
    id: string;
    join: boolean;
}

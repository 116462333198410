import React from 'react';

import strings from '../../../strings';
import Button from '../../../uiComponents/Button';

function EditButton(param: { onClick?: () => void }) {
    const { onClick } = param;

    return (
        <Button
            text={strings.EDIT}
            variant={'edit'}
            disabled={true}
            onClick={onClick}
        />
    );
}

export default EditButton;

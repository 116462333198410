import React, { ChangeEvent } from 'react';
import { IDropDown } from './types';

function DropDown(param: IDropDown) {
    const { items, title, variant, label, onSelected } = param;

    // const [selected, setSelected] = useState(0);

    const onChangeHandle = (event: ChangeEvent<HTMLSelectElement>) => {
        // setSelected(+event.target.value);
        onSelected && onSelected(+event.target.value);
    };

    return (
        <React.Fragment>
            {label && (
                <div
                    className={`dropdown-label${
                        variant ? ' dropdown-label-' + variant : ''
                    }`}
                >
                    {label}
                </div>
            )}
            <div className={`dropdown${variant ? ' dropdown-' + variant : ''}`}>
                <select
                    className={`dropdown-select${
                        variant ? ' dropdown-select-' + variant : ''
                    }`}
                    name={variant || 'dropDown'}
                    id={variant || 'dropDown'}
                    onChange={onChangeHandle}
                    defaultValue={title && title}
                >
                    {[
                        title ? (
                            <option style={{ display: 'none' }} key={-1}>
                                {title}
                            </option>
                        ) : null,
                        ...(items as any[]).map(
                            (itemText: any, index: number) => (
                                <option
                                    className="dropdown-option"
                                    key={index}
                                    value={index}
                                >
                                    {itemText}
                                </option>
                            )
                        ),
                    ]}
                </select>
            </div>
        </React.Fragment>
    );
}

export default DropDown;

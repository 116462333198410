import React from 'react';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../actions';
import {
    EEventFilter, EEventSwitcher, IDefaultDispatchProps, IDefaultProps, IDefaultState,
    RefreshTokenType, SignInData
} from '../types';
import { withAuthentication } from './withAuthentication';

export const withState = (
    WrappedComponent: React.ComponentType<IDefaultProps>,
    ownProps?: any
) => {
    const mapStateToProps = (state: IDefaultState): IDefaultState => ({
        ...state,
    });

    const mapDispatchToProps = (dispatch: any): IDefaultDispatchProps => ({
        signIn: (signInData: SignInData) =>
            dispatch(
                getActionFunction(actionTypes.authUser.loading, signInData)
            ),
        signInRefreshToken: (refreshToken: RefreshTokenType) =>
            dispatch(
                getActionFunction(actionTypes.authUser.loading, refreshToken)
            ),
        logOut: () =>
            dispatch(getActionFunction(actionTypes.authUser.success, {})),
        getEvents: () =>
            dispatch(getActionFunction(actionTypes.events.getAll.loading)),
        eventFilterUpdate: (eventFilter: EEventFilter) =>
            dispatch(
                getActionFunction(
                    actionTypes.app.eventFilterUpdate,
                    eventFilter
                )
            ),
        eventSwitcherUpdate: (eventSwitcher: EEventSwitcher) =>
            dispatch(
                getActionFunction(
                    actionTypes.app.eventSwitcherUpdate,
                    eventSwitcher
                )
            ),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withAuthentication(
            class WithState extends React.Component<
                IDefaultProps,
                IDefaultProps
            > {
                render() {
                    return <WrappedComponent {...this.props} {...ownProps} />;
                }
            }
        )
    );
};

import React from 'react';

import strings from '../../../strings';
import AttendButton from './AttendButton';
import { IAttendButton } from '../../../uiComponents/types';

function LeaveButton(param: IAttendButton) {
    const { id, text, disabled, onClick } = param;

    return (
        <AttendButton
            id={id}
            join={false}
            text={text || strings.LEAVE}
            disabled={disabled}
            onClick={onClick}
        />
    );
}

export default LeaveButton;

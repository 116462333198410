import React, { useState } from 'react';

import xIcon from '../../assets/images/x.svg';
import xIconMini from '../../assets/images/xMini.svg';
import Button from '.';
import { ICloseButton } from '../types';

function CloseButton(param: ICloseButton) {
    const { linkTo, mini, onClick } = param;
    const [hover, setHover] = useState(false);

    const onMouseOver = (
        event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
        setHover(true);
    };
    const onMouseOut = (
        event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
        setHover(false);
    };

    return (
        <Button
            variant={'close'}
            text={
                <div
                    className={'close-container'}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    <div
                        className="close-icon"
                        style={{
                            maskImage: `url(${mini ? xIconMini : xIcon})`,
                            WebkitMaskImage: `url(${mini ? xIconMini : xIcon})`,
                        }}
                    >
                        <div
                            className={`close-icon-mask${
                                hover ? '-hover' : ''
                            }`}
                        ></div>
                    </div>
                    <div className="close-text">Close</div>
                </div>
            }
            onClick={onClick}
            linkTo={linkTo}
        />
    );
}

export default CloseButton;

import { actionTypes } from '../actions';
import { EEventFilter, EEventSwitcher, IEventData } from '../types';
import { updatedEventsData } from './utils';

export const initialState = {
    app: {
        isMobile: false,
        eventFilter: EEventFilter.all,
        eventSwitcher: EEventSwitcher.grid,
    },
    authUser: {
        data: null,
        loading: false,
        error: '',
    },
    events: {
        data: null,
        loading: false,
        error: '',
    },
    createEvent: {
        data: null,
        loading: false,
        error: '',
    },
    attend: {
        loading: false,
        error: '',
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.app.eventFilterUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    eventFilter: action.data,
                },
            };
        case actionTypes.app.eventSwitcherUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    eventSwitcher: action.data,
                },
            };
        case actionTypes.app.isMobileUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    isMobile: action.data,
                },
            };

        case actionTypes.authUser.loading:
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    loading: true,
                    error: '',
                },
            };

        case actionTypes.authUser.error:
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    loading: false,
                    error: action.error,
                },
            };

        case actionTypes.authUser.success:
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    loading: false,
                    data: action.data,
                },
            };

        case actionTypes.events.getAll.noLoading:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    error: '',
                },
            };
        case actionTypes.events.getAll.loading:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: true,
                    error: '',
                },
            };

        case actionTypes.events.getAll.error:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    error: action.error,
                },
            };

        case actionTypes.events.getAll.success:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    data: action.data,
                },
            };

        case actionTypes.events.create.loading:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: true,
                },
                createEvent: {
                    ...state.createEvent,
                    loading: true,
                    error: '',
                },
            };

        case actionTypes.events.create.error:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                },
                createEvent: {
                    ...state.createEvent,
                    loading: false,
                    error: action.error,
                },
            };

        case actionTypes.events.create.success:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    data: (state.events.data === null && [action.data]) || [
                        ...((state.events.data as unknown) as IEventData[]),
                        action.data,
                    ],
                },
                createEvent: {
                    ...state.createEvent,
                    loading: false,
                },
            };

        case actionTypes.events.attend.loading:
            return {
                ...state,
                attend: {
                    ...state.attend,
                    loading: true,
                    error: '',
                },
            };

        case actionTypes.events.attend.error:
            return {
                ...state,
                attend: {
                    ...state.events,
                    loading: false,
                    error: action.error,
                },
            };

        case actionTypes.events.attend.success:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: false,
                    data: updatedEventsData(state.events.data, action.data),
                },
                attend: {
                    ...state.events,
                    loading: false,
                },
            };

        default:
            return state;
    }
};
export default reducer;

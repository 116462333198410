import React from 'react';

import backgroundImage from '../../assets/images/head.svg';
import LeftPanelLayout from '../../components/LeftPanelLayout';
import strings from '../../strings';
import Button from '../../uiComponents/Button';

function Page404() {
    return (
        <LeftPanelLayout
            content={
                <React.Fragment>
                    <div
                        style={{ backgroundImage: `url(${backgroundImage})` }}
                        className="page-404-background-image"
                    />{' '}
                    <div className="content">
                        <h1>{strings.PAGE_404_TITLE}</h1>
                        <div className="message info-color">
                            {strings.PAGE_404_DESCRIPTION.split('\n').map(
                                (line: string, index: number) => (
                                    <React.Fragment key={`line${index}`}>
                                        {`${line}`}
                                        <br />
                                    </React.Fragment>
                                )
                            )}
                        </div>
                        <Button
                            text={strings.REFRESH}
                            variant={'dark'}
                            key={'refresh'}
                            linkTo={'/'}
                        />
                    </div>
                </React.Fragment>
            }
        />
    );
}
export default Page404;

import React from 'react';
import { connect } from 'react-redux';
import BasicLayout from '../../components/BasicLayout/index';
import strings from '../../strings';
import { IDefaultState, IInStore, PathNameType } from '../../types';
import CloseButton from '../../uiComponents/Button/CloseButton';
import CreateEventForm from './CreateEventForm';

interface ICreateEventFromStateProps {
    createEvent: IInStore;
}
interface IOwnProps {
    fromLocation?: PathNameType;
}
interface IStateProps extends IDefaultState, ICreateEventFromStateProps {}

interface IProps extends IStateProps, IOwnProps {}

function CreateEvent(props: IProps = {} as IProps) {
    const { createEvent, fromLocation } = props;
    const { isMobile } = props.app;
    return (
        <BasicLayout
            content={
                <div className="content">
                    <div className="create-event-card">
                        <h1>{strings.CREATE_NEW_EVENT_TITLE}</h1>
                        {createEvent.error ? (
                            <div className="message error-color">
                                {strings.CREATE_NEW_EVENT_ERROR_MESSAGE}
                            </div>
                        ) : (
                            <div className="message info-color">
                                {strings.CREATE_NEW_EVENT_HINT}
                            </div>
                        )}
                        <CreateEventForm linkTo={fromLocation || '/'} />
                    </div>
                </div>
            }
            rightHeaderComponent={
                <CloseButton linkTo={fromLocation || '/'} mini={isMobile} />
            }
        />
    );
}

const mapStateToProps = (state: IStateProps, ownProps: IOwnProps): IProps => ({
    ...state,
    ...ownProps,
});

export default connect(mapStateToProps, null)(CreateEvent);

import { IEventData } from '../types';

export const updatedEventsData = (
    eventsData: IEventData[] | null,
    updatedEvent: IEventData
): IEventData[] =>
    eventsData !== null
        ? [
              // filter would be more efficient, but it is not stable (would change the order)
              ...eventsData.map((eventData: IEventData) =>
                  eventData._id === updatedEvent._id ? updatedEvent : eventData
              ),
          ]
        : [updatedEvent];

import './assets/fonts/hind/Hind-Medium.ttf';
import './assets/fonts/hind/Hind-Bold.ttf';
import './assets/fonts/playFair/PlayfairDisplay-Regular.ttf';

import './index.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
// import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import MainRouter from './MainRouter';
import reducer from './reducers';
import rootSaga from './sagas';
import * as serviceWorker from './serviceWorker';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));
// const store = createStore(reducer, applyMiddleware(sagaMiddleware, logger));
// store.subscribe(() => console.log(store.getState()));
sagaMiddleware.run(rootSaga);

render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Route path="/">
                    <MainRouter />
                </Route>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

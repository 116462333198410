import React from 'react';

import BasicLayoutHeader from './BasicLayoutHeader';
import Profile from '../Profile';

interface LeftPanelProps {
    content: JSX.Element;
    rightHeaderComponent?: JSX.Element;
}
function BasicLayout(params: LeftPanelProps) {
    const { content, rightHeaderComponent } = params;
    return (
        <React.Fragment>
            <BasicLayoutHeader
                rightComponent={rightHeaderComponent || <Profile />}
            />
            <div className="basic-layout-content-container">{content}</div>
        </React.Fragment>
    );
}

export default BasicLayout;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import userIcon from '../../../assets/images/user.svg';
import {
    EventSwitcher,
    IDefaultState,
    IEventData,
    IUser,
} from '../../../types';
import EditButton from '../buttons/EditButton';
import JoinButton from '../buttons/JoinButton';
import LeaveButton from '../buttons/LeaveButton';

interface IOwnProps {
    event: IEventData;
    eventSwitcherString: EventSwitcher;
}
interface IProps extends IDefaultState, IOwnProps {}

/**
 * @param dateISOString date string in ISO format
 * @return string Month DD, YYYY - H:MM AM/PM
 */
const getDateTime = (dateISOString: string): string => {
    let dateObject: Date = new Date(dateISOString);
    const dateString: string = dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const timeString: string = dateObject.toLocaleTimeString('en-US', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
    });
    return `${dateString} - ${timeString}`;
};
function SingleEvent(props: IProps = {} as IProps) {
    const { authUser, event, eventSwitcherString } = props;

    const [startsAt, setStartsAt] = useState('');
    const [dateTime, setDateTime] = useState('');

    useEffect(() => {
        if (event.startsAt !== startsAt) {
            setStartsAt(event.startsAt);
            setDateTime(getDateTime(event.startsAt));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    const amIInEvent = (): boolean =>
        event.attendees.filter(
            (attendee: IUser) => attendee.id === authUser.data.id
        ).length > 0;

    const amIOwner = (): boolean => event.owner.id === authUser.data.id;

    return (
        <div className={`event-card-${eventSwitcherString}`} key={event._id}>
            <div className={`event-card-datetime-${eventSwitcherString}`}>
                {dateTime}
            </div>
            <div className={`event-card-title-${eventSwitcherString}`}>
                {event.title}
            </div>
            <div className={`event-card-owner-${eventSwitcherString}`}>
                {`${event.owner.firstName} ${event.owner.lastName}`}
            </div>
            <div className={`event-card-description-${eventSwitcherString}`}>
                {event.description}
            </div>

            <div className={`event-card-bottom-panel-${eventSwitcherString}`}>
                <span
                    className={`event-card-bottom-panel-attendees-${eventSwitcherString}`}
                >
                    {eventSwitcherString === ('grid' as EventSwitcher) && (
                        <span
                            className={`event-card-bottom-panel-attendees-icon-${eventSwitcherString}`}
                        >
                            <img src={userIcon} alt={'user'} />
                        </span>
                    )}
                    {event.attendees.length} of {event.capacity}
                </span>
                <span
                    className={`event-card-bottom-panel-button-${eventSwitcherString}`}
                >
                    {amIOwner() ? (
                        <EditButton key={event._id} />
                    ) : amIInEvent() ? (
                        <LeaveButton
                            key={event._id}
                            id={event._id}
                            disabled={Date.parse(event.startsAt) <= Date.now()}
                        />
                    ) : (
                        <JoinButton
                            key={event._id}
                            id={event._id}
                            disabled={
                                event.attendees.length >= event.capacity ||
                                Date.parse(event.startsAt) <= Date.now()
                            }
                        />
                    )}
                </span>
            </div>
        </div>
    );
}

const mapStateToProps = (
    state: IDefaultState,
    ownProps: IOwnProps
): IProps => ({ ...state, ...ownProps });

export default connect(mapStateToProps, null)(SingleEvent);
